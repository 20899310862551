import { FC, useRef } from 'react'
import { useRouter } from 'next/router' // we need this to make JSX compile

type NotFoundType = {}

const NotFound: FC<NotFoundType> = ({}) => {
  const router = useRouter()
  const redirected = useRef(false)
  if (!redirected.current && typeof window !== 'undefined') {
    router.replace('/')
    redirected.current = true
  }
  return <div className='text-center'></div>
}

export default NotFound
